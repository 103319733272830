const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Tarifador' }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v2/tarifador/chamadas',
  name: 'route_billing_calls',
  component: () => import('@/modules/billing/views/BillingCalls.vue'),
  meta: {
    title: 'Detalhamento de chamadas',
    breadcrumbs: baseBreadcrumbs({ label: 'Chamadas' }),
    showFilterButton: true,
    showExportButton: true
  }
}
]
