<script setup>
import FilterIcon from '@/assets/icons/FilterIcon.vue'
import ExportIcon from '@/assets/icons/ExportIcon.vue'
import { onMounted, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const showFilterIcon = computed(() => {
  return route.meta.showFilterButton ?? false
})

const showExportButton = computed(() => {
  return route.meta.showExportButton ?? false
})

const showJSONExport = computed(() => (route.meta.showJSONExport ?? true))

const toggleFilter = () => {
  const event = new CustomEvent('toggle-filter-event')
  window.dispatchEvent(event)
}

const isLoadingExport = ref(false)

const handleLoadingExportChange = (value) => {
  isLoadingExport.value = !isLoadingExport.value
}

const emitExportEvent = (typeExport) => {
  const eventExport = new CustomEvent('export-event', {
    detail: { typeExport }
  })

  window.dispatchEvent(eventExport)
}

onMounted(() => {
  window.addEventListener('loading-status-changed', handleLoadingExportChange)
})
</script>

<template>
  <div
    class="toolbar-container"
    v-if="showFilterIcon"
  >
    <q-btn-group
      style="border: 1px solid #ccc; border-radius: 3px; box-shadow: none;"
    >
      <q-btn
        dense
        @click="toggleFilter"
        class="common-button"
      >
        <FilterIcon
          color="grey"
          width="18"
        />
        <q-tooltip>Filtros</q-tooltip>
      </q-btn>

      <q-btn
        v-if="showExportButton"
        dense
        class="common-button"
        :loading="isLoadingExport"
      >
        <ExportIcon
          color="grey"
          width="18"
          height="18"
        />
        <q-menu
          anchor="top right"
          self="bottom right"
        >
          <q-list
            dense
            separator
          >
            <q-item
              clickable
              @click="emitExportEvent('csv')"
              v-close-popup
            >
              <q-item-section>
                <q-item-label>Exportar CSV</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              v-if="showJSONExport"
              clickable
              @click="emitExportEvent('json')"
              v-close-popup
            >
              <q-item-section>
                <q-item-label>Exportar JSON</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>

        <q-tooltip>Exportar</q-tooltip>
      </q-btn>
    </q-btn-group>
  </div>
</template>

<style scoped lang="sass">
    .toolbar-container
      padding: 4px 0 4px 16px
      display: flex

    .common-button
      border-right: 1px solid #ccc

    .common-button:last-child
      border-right: none
</style>
