const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Chats' }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [
  {
    path: '/v2/conversas/por-requisicoes',
    name: 'route_chat_requests',
    component: () => import('@/modules/requests/UraApiRequests.vue'),
    meta: {
      title: 'Relatório de Requisições',
      breadcrumbs: baseBreadcrumbs({ label: 'Requisições das API\'s' }),
      showFilterButton: true,
      showExportButton: true
    }
  }
]
