const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Dashboards' }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v2/dashboards/atendimentos',
  name: 'route_dashboards_atendimentos',
  component: () => import('@/modules/dashboards/views/DashboardView.vue'),
  meta: {
    title: 'Dashboard de Atendimentos',
    breadcrumbs: baseBreadcrumbs({ label: 'Atendimentos' }),
    showQueueSelector: true
  }
},
{
  path: '/v2/dashboards/desempenho',
  name: 'route_dashboards_desempenho',
  component: () => import('@/modules/dashboards/views/OnlineTV.vue'),
  meta: {
    title: 'TV Online',
    breadcrumbs: baseBreadcrumbs({ label: 'TV Online' }),
    showQueueSelector: true
  }
},
{
  path: '/v2/dashboards/:pathMatch(.*)*',
  redirect: { path: '/v2/dashboards/atendimentos' }
}]
