import { createRouter, createWebHistory } from 'vue-router'
import pagesRoutes from '@/modules/pages/routes.js'
import dashboardRoutes from '@/modules/dashboards/routes.js'
import callRoutes from '@/modules/calls/routes.js'
import agentRoutes from '@/modules/agents/routes.js'
import chatRoutes from '@/modules/chats/routes.js'
import billingRoutes from '@/modules/billing/routes.js'
import { usePreferencesStore } from '@/modules/preferences/preferencesStore'

let router = null

export default (store) => {
  if (!router) router = makeRouter()

  router.beforeEach(async (route, from, next) => {
    const preferencesStore = usePreferencesStore()
    const session = await window.getAuthSession()
    const isAuthenticated = Boolean(session)

    if (isAuthenticated) {
      const preferences = await preferencesStore.getPreferences()
      const restrictedAccess = preferences.restrictedAccess
      const tabMatch = route.path.match(/\/v2\/([^/]+)/) ?? route.path.match(/\/v1\/([^/]+)/)
      const currentTab = tabMatch[1]
      const translatedTab = translateTab[currentTab] || currentTab

      const isDashboardRoute = currentTab === 'dashboards'
      const tabBlocked = !preferences.tabs.includes(translatedTab)

      if ((isDashboardRoute && restrictedAccess) || tabBlocked) window.location.href = '/v1/acesso-bloqueado'

      return next()
    }

    window.location.href = '/acesso/entrar'
  })

  return router
}

const makeRouter = () => {
  const history = createWebHistory('/')

  return createRouter({
    history,
    routes: [].concat(
      pagesRoutes,
      dashboardRoutes,
      callRoutes,
      agentRoutes,
      chatRoutes,
      billingRoutes,
      {
        path: '/*',
        redirect: { path: '/v2/dashboards/one' }
      }
    )
  })
}

const translateTab = {
  chamadas: 'calls',
  tarifador: 'billing',
  dashboards: 'dashboards',
  agentes: 'agents',
  conversas: 'chats'
}
