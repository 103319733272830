import script from "./QueuePreferenceSelector.vue?vue&type=script&setup=true&lang=js"
export * from "./QueuePreferenceSelector.vue?vue&type=script&setup=true&lang=js"

import "./QueuePreferenceSelector.vue?vue&type=style&index=0&id=b447e4be&lang=sass"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QCheckbox,QSeparator,QIcon,QField});
