<script setup>
import { usePreferencesStore } from '@/modules/preferences/preferencesStore'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const showQueueSelector = computed(() => {
  return route.meta.showQueueSelector ?? false
})

const store = usePreferencesStore()

const selectRef = ref(null)
const options = ref([])
const selectedOptions = defineModel({ default: [] })
const isOpen = ref(false)
const hideLabel = ref(false)
const filteredOptions = ref([])

onMounted(async () => {
  const preferences = await store.getPreferences()

  options.value = preferences.allowedQueues.filter(
    queue => ['t', 'y', '1', 'true', 'yes'].includes(String(queue.active || 0).toLowerCase().trim())
  )

  selectedOptions.value = preferences.activeQueues
})

const toggleSelectAll = () => {
  if (selectedOptions.value.length === options.value.length) {
    selectedOptions.value = []
  } else {
    selectedOptions.value = options.value.map(opt => opt.id)
  }
}

const toggleItem = (id) => {
  if (selectedOptions.value.includes(id)) {
    selectedOptions.value = selectedOptions.value.filter(item => item !== id)
  } else {
    selectedOptions.value = [...selectedOptions.value, id]
  }
}

const filterOptions = (val, update) => {
  update(() => {
    filteredOptions.value = options.value.filter(opt =>
      opt.name.toLowerCase().includes(val.toLowerCase())
    )
  })
}

const shouldUsesInput = computed(() => {
  return Array.isArray(options.value) && options.value.length > 9
})

const label = computed(() => {
  if (selectedOptions.value.length === 0) {
    return 'Nenhuma fila selecionada'
  }
  if (selectedOptions.value.length === 1) {
    const selectedItem = options.value.find(opt => opt.id === selectedOptions.value[0])
    return selectedItem ? selectedItem.name : '1 item selecionado'
  }
  if (selectedOptions.value.length === options.value.length) { return 'Todas as filas selecionadas' }

  return `${selectedOptions.value.length} filas selecionadas`
})

const reorderOptions = () => {
  filteredOptions.value = [
    ...options.value.filter(opt => selectedOptions.value.includes(opt.id)),
    ...options.value.filter(opt => !selectedOptions.value.includes(opt.id))
  ]
}

const handleCloseOptions = () => {
  isOpen.value = false
  filteredOptions.value = options.value

  const currentActiveQueues = store.getActiveQueues
  const selected = selectedOptions.value

  const isQueuesUnchanged = JSON.stringify(currentActiveQueues.sort()) === JSON.stringify(selected.sort())
  if (isQueuesUnchanged) return

  store.savePreferences({ activeQueues: selected })
}

const handleShowOptions = () => {
  isOpen.value = true
  reorderOptions()
  hideLabel.value = true
}

const handleBlur = () => {
  hideLabel.value = false
  if (selectRef.value) {
    selectRef.value.updateInputValue('')
  }
}

</script>

<template>
  <div
    class="dash-queues"
    v-if="showQueueSelector"
  >
    <q-select
      ref="selectRef"
      v-model="selectedOptions"
      :options="filteredOptions"
      :class="['my-dense-select-queues col',
               shouldUsesInput ? (isOpen ? 'is-open' : 'is-close') : '',
               hideLabel ? 'hide-label' : '' ]"
      option-label="name"
      option-value="id"
      square
      borderless
      popup-content-class="queue-selector-popup-style"
      popup-content-style="box-shadow:0 1px 5px rgba(0,0,0,.2),0 2px 2px rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12)"
      virtual-scroll-slice-size="9999"
      hide-dropdown-icon
      emit-value
      autocomplete
      multiple
      :use-input="shouldUsesInput"
      use-input-value
      @filter="filterOptions"
      @popup-show="handleShowOptions"
      @popup-hide="handleCloseOptions"
      @blur="handleBlur"
    >
      <template #selected>
        <span class="value-label q-px-sm cursor-pointer ">
          <span>{{ label }}</span>
        </span>
      </template>

      <template #option="scope">
        <div class="row items-center q-pr-xs">
          <q-checkbox
            dense
            size="xs"
            keep-color
            v-model="selectedOptions"
            class="col-auto q-pa-sm"
            :val="scope.opt.id"
          />

          <div
            class="col cursor-pointer font-text"
            @click="toggleItem(scope.opt.id)"
          >
            {{ scope.opt.name }}
          </div>
        </div>
        <q-separator />
      </template>

      <template #before-options>
        <div
          class="row items-center"
        >
          <q-checkbox
            dense
            size="xs"
            keep-color
            :class="['col-auto q-pa-sm']"
            :model-value="selectedOptions.length === options.length"
            @click="toggleSelectAll"
          />
          <div
            class="col cursor-pointer font-text"
            @click="toggleSelectAll"
          >
            Selecionar todos
          </div>
        </div>
        <q-separator />
      </template>

      <template #append>
        <q-icon
          size="20px"
          name="mdi-chevron-up"
          color="#616161"
          style="margin-top:5px; margin-right: 5px"
          v-show="isOpen"
        />

        <q-icon
          size="20px"
          name="mdi-chevron-down"
          color="#616161"
          style="margin-top:5px; margin-right: 5px"
          v-show="!isOpen"
        />
      </template>
    </q-select>
  </div>
</template>

<style lang="sass">
.dash-queues

  .q-field__marginal
    height: 20px
    cursor: pointer
  .my-dense-select-queues
    .value-label
      font-family: 'Work-sans'
      font-size: 13px
      font-weight: 400
      color: #616161
      width: 100%
      white-space: nowrap
    .q-field__control
      min-height: 0
      width: 100%
      border: 1px solid #C5C5C5
      background-color: #FFFFFF
    .q-field__control-container
      width: 200px
      .q-field__native
        padding: 0
        min-height: 26px
        line-height: 12px

    .q-field__input
      font-family: 'Work-sans'
      font-size: 12px
      font-weight: 400
      color: #616161
      width: 100%

    .q-field__append
      padding-left: 0

  .my-dense-select-queues.is-close
    :is(.q-field__input)
      position: absolute

  .my-dense-select-queues
    &.hide-label
      .value-label
        display: none
.queue-selector-popup-style
  width: 170px
  max-height: 65vh !important

.font-text
  color: #616161
  font-size: 13px
  font-family: 'Work-sans'
</style>
